import {Injectable} from '@angular/core';
import {User} from "@app/models/User";

export const USER_KEY = 'currentUser';
const PHONE_KEY = 'lastUserPhone';
const LOADED_KEY = 'loaded';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    clean(): void {
        window.sessionStorage.clear();
    }

    public saveUser(user: User): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    public getUser(): User {
        const user = window.sessionStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }
        return <User>{};
    }

    public isLoggedIn(): boolean {
        const user = window.sessionStorage.getItem(USER_KEY);
        return !!user;
    }

    public saveLastUsedPhone(phone: string): void {
        window.sessionStorage.removeItem(PHONE_KEY);
        window.sessionStorage.setItem(PHONE_KEY, phone);

    }

    public getLastUsedPhone(): string {
        return window.sessionStorage.getItem(PHONE_KEY) ?? '';
    }

    public saveLoaded(): void {
        window.sessionStorage.removeItem(LOADED_KEY);
        window.sessionStorage.setItem(LOADED_KEY, '1');

    }

    public getLoaded(): string {
        return window.sessionStorage.getItem(LOADED_KEY) ?? '';
    }
}
