import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MedicalInstitution } from '@app/models/MedicalInstitution';
import { User } from '@app/models/User';
import { AuthService } from '@app/services/auth.service';
import { MedicalInstitutionsService } from '@app/services/medicalInstitutions.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-medical-institutions',
  templateUrl: './medical-institutions.component.html',
  styleUrls: ['./medical-institutions.component.scss']
})
export class MedicalInstitutionsComponent implements OnInit {

  @Output() medicalInstitution = new EventEmitter<MedicalInstitution | null>();

  medicalInstitutions: string[] = [];
  allMedicalInstitutions: MedicalInstitution[] = [];
  selectedMedicalInstitution: string = '';

  constructor(
    private authService: AuthService,
    private medicalInstitutionService: MedicalInstitutionsService,
    private storage: StorageService
  ) {
    
  }

  user: User = this.storage.getUser();

  ngOnInit() {
    if (this.user.orchestratorId) {
      this.medicalInstitutionService.getPatientMedicalInstitutions().subscribe({
        next: (data) => {
          this.allMedicalInstitutions = data;
          if (data.length > 0) {
            this.selectedMedicalInstitution = data.find(el => el.orchestratorId === this.user.orchestratorId)?.name ?? '';
            this.medicalInstitutionSelected(this.selectedMedicalInstitution);
          }
        },
      });
    } else {
      this.medicalInstitutionService.getMedicalInstitutions().subscribe({
        next: (data) => {
          this.allMedicalInstitutions = data;
        },
      });
    }
  }

  getMedicalInstitutionByName(name: string): MedicalInstitution | null {
    let c = this.allMedicalInstitutions.filter(el => el.name === name);
    if (c.length > 0) {
      return c[0];
    }

    return null;
  }

  medicalInstitutionSelected(selectedMedicalInstitutionName: string) {
    const mi = this.getMedicalInstitutionByName(selectedMedicalInstitutionName);

    if (this.user.orchestratorId && mi?.orchestratorId !== this.user.orchestratorId) {
      console.log(mi?.orchestratorId + ' ' + this.user.orchestratorId);
    }

    if (this.user.orchestratorId
        && mi?.orchestratorId
        && mi.orchestratorId !== this.user.orchestratorId) {
        this.authService.changeToken(mi.orchestratorId).subscribe({
        next: (_resp: any) => {
          window.location.reload();
        },
      });
    }

    this.medicalInstitution.emit(mi);

    return this.medicalInstitution
  }

  searchMedicalInstitution(e: any) {
    if (e.hasOwnProperty('query') && e['query'].length > 0) {
      this.medicalInstitutions = this.allMedicalInstitutions.filter(el => {
        return el.name.toLowerCase().indexOf(e['query'].toLowerCase()) !== -1;
      }).map(el => {
        return el.name;
      });
    } else {
      this.medicalInstitutions = this.allMedicalInstitutions.map(el => {
        return el.name;
      });
    }
  }
}
