<div class="homeContainer">
    <div class="carousel-container">
        <div id="carouselBanners" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <ng-container *ngFor="let banner of banners; let isFirst = first; let i = index;" class="carousel-inner">
                <div class="carousel-item" [class.active]="isFirst" data-bs-interval="5000">
                    <div class="carousel-content">
                        <div class="carousel-header">ГОРЯЧАЯ ЛИНИЯ:<br />ИНФО-ДИАБЕТ СЕМАШКО</div>
                        <ul>
                            <li>
                                Лечение сахарного диабета по программе ОМС из всех регионов РФ
                            </li>
                            <li>
                                Лечение сахарного диабета по программе ДМС
                            </li>
                            <li>
                                Лечение и профилактика сахарного диабета на платной основе
                            </li>
                        </ul>
                        <div>
                            <img [src]="banner.imageMobileUrl" class="carousel-img__mobile" alt="Баннер">
                        </div>
                        <div class="other">
                            <div>
                                <a [href]="banner.bannerUrl" class="o-button">Подробнее</a>
                            </div>
                            <div class="carousel-control">
                                <button class="carousel-control-button" type="button" data-bs-target="#carouselBanners" data-bs-slide="prev">
                                    <i class="pi pi-arrow-left"></i>
                                </button>
                                <button class="carousel-control-button" type="button" data-bs-target="#carouselBanners" data-bs-slide="next">
                                    <i class="pi pi-arrow-right"></i>
                                </button>
                                <div class="carousel-control-indicator">
                                    {{i + 1}}/{{banners.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-image">
                        <img [src]="banner.imageDesktopUrl" class="carousel-img__desktop" alt="Баннер">
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <app-diagram [isHomePage]="true"></app-diagram>
</div>