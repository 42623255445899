import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {JwtInterceptor} from "@app/helpers/jwt.interceptor";
import {ErrorInterceptor} from "@app/helpers/error.interceptor";
import {LoginComponent} from "@app/components/login/login.component";
import {HomeComponent} from "@app/components/home/home.component";
import {ButtonModule} from "primeng/button";
import {MyDataComponent} from './components/my-data/my-data.component';
import {MenubarModule} from "primeng/menubar";
import {DockModule} from "primeng/dock";
import {TabViewModule} from "primeng/tabview";
import {TabMenuModule} from "primeng/tabmenu";
import {StyleClassModule} from "primeng/styleclass";
import {CardModule} from "primeng/card";
import {InputMaskModule} from 'primeng/inputmask';
import {PasswordModule} from "primeng/password";
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from "primeng/inputnumber";
import {ProfileComponent} from './components/my-data/profile/profile.component';
import {RecordsComponent} from './components/my-data/records/records.component';
import {SetPasswordComponent} from './components/my-data/set-password/set-password.component';
import {BookComponent} from './components/book/book.component';
import {MessagesComponent} from './components/messages/messages.component';
import {MedkartaComponent} from "@app/components/my-data/medkarta/medkarta.component";
import {ResultsComponent} from './components/my-data/medkarta/results/results.component';
import {ReferralsComponent} from './components/referrals/referrals.component';
import {SlideMenuModule} from "primeng/slidemenu";
import {ToastModule} from 'primeng/toast';
import {ResearchesComponent} from "@app/components/my-data/medkarta/researches/researches.component";
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DocumentListComponent} from './components/document-list/document-list.component';
import {ContactsComponent} from './components/contacts/contacts.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import { NotificationsComponent } from './components/notifications/notifications.component';
import { BookingsComponent } from './components/my-data/bookings/bookings.component';
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ListboxModule} from "primeng/listbox";
import {CalendarModule} from "primeng/calendar";
import {RippleModule} from "primeng/ripple";
import {ImageModule} from "primeng/image";
import {ArchiveComponent} from "@app/components/my-data/medkarta/archive/archive.component";
import {TableModule} from "primeng/table";
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { NcdsElderlyComponent } from '@app/components/questioning/questionnaires/ncds/elderlyAge/ncds-elderly.component';
import { QuestionnaireComponent } from './components/questioning/questionnaires/questionnaire/questionnaire.component';
import { VitamineB1Component } from './components/health-status/health-calculators/vitamine-b1/vitamine-b1.component';
import { VitamineB2Component } from './components/health-status/health-calculators/vitamine-b2/vitamine-b2.component';
import { VitamineDComponent } from './components/health-status/health-calculators/vitamine-d/vitamine-d.component';
import { GastritisComponent } from './components/health-status/health-calculators/gastritis/gastritis.component';
import { VitaminAComponent } from './components/health-status/health-calculators/vitamin-a/vitamin-a.component';
import { AppendixComponent } from './components/health-status/health-calculators/appendix/appendix.component';
import {CaloriesComponent} from './components/health-status/health-calculators/calories/calories.component';
import { WaterComponent } from '@app/components/health-status/health-calculators/water/water.component';
import { NCDsComponent } from './components/questioning/questionnaires/ncds/middleAge/ncds.component';
import { CalcComponent } from './components/health-status/health-calculators/calc/calc.component';
import { CopdComponent } from './components/health-status/health-calculators/copd/copd.component';
import { CovidComponent } from './components/questioning/questionnaires/covid/covid.component';
import { BMIComponent } from './components/health-status/health-calculators/bmi/bmi.component';
import {HealthStatusComponent} from './components/health-status/health-status.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuModule } from 'primeng/menu';
import { MenuComponent } from './components/menu/menu.component';
import { DividerModule } from 'primeng/divider';
import { BadgeModule } from 'primeng/badge';
import { ConclusionsComponent } from '@app/components/my-data/medkarta/conclusions/conclusions.component';
import { SpecialtyComponent } from './components/book/specialty/specialty.component';
import { QuestioningComponent } from './components/questioning/questioning.component';
import { AlertComponent } from './components/alert/alert.component';
import { DialogModule } from "primeng/dialog";
import { DialogsComponent } from './components/dialogs/dialogs.component';
import {MessagesModule} from 'primeng/messages';
import {QrCodeModule} from "ng-qrcode";
import { DiagramComponent } from './components/dialogs/diagram/diagram/diagram.component';
import { MedicalInstitutionsComponent } from './components/medical-institutions/medical-institutions.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        MyDataComponent,
        MedkartaComponent,
        ProfileComponent,
        RecordsComponent,
        SetPasswordComponent,
        BookComponent,
        MessagesComponent,
        ResultsComponent,
        ReferralsComponent,
        ResearchesComponent,
        DocumentListComponent,
        ContactsComponent,
        NotificationsComponent,
        BookingsComponent,
        ArchiveComponent,
        PrivacyPolicyComponent,
        QuestionnaireComponent,
        HealthStatusComponent,
        NcdsElderlyComponent,
        QuestioningComponent,
        VitamineB1Component,
        VitamineB2Component,
        GastritisComponent,
        VitamineDComponent,
        CaloriesComponent,
        VitaminAComponent,
        AppendixComponent,
        AlertComponent,
        WaterComponent,
        CovidComponent,
        CalcComponent,
        CopdComponent,
        NCDsComponent,
        BMIComponent,
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        ConclusionsComponent,
        SpecialtyComponent,
        DialogsComponent,
        DiagramComponent,
        MedicalInstitutionsComponent,
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ButtonModule,
        MenubarModule,
        DockModule,
        TabViewModule,
        TabMenuModule,
        StyleClassModule,
        InputMaskModule,
        CardModule,
        PasswordModule,
        InputNumberModule,
        InputTextModule,
        SlideMenuModule,
        ToastModule,
        PdfViewerModule,
        CarouselModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        AutoCompleteModule,
        FormsModule,
        ListboxModule,
        CalendarModule,
        RippleModule,
        ImageModule,
        TableModule,
        MenuModule,
        DividerModule,
        BadgeModule,
        DialogModule,
        MessagesModule,
        QrCodeModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        ConfirmationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
