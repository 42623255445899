<p-autoComplete
    id="chooseMedicalInstitution"
    [dropdown]="true"
    [suggestions]="medicalInstitutions"
    [dropdownMode]="'blank'"
    placeholder="Выберите территорию"
    [(ngModel)]="selectedMedicalInstitution"
    (onSelect)="medicalInstitutionSelected($event)"
    [ngClass]="{'selected': selectedMedicalInstitution}"
    (completeMethod)="searchMedicalInstitution($event)"
>
</p-autoComplete>