import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MedicalInstitution } from '@app/models/MedicalInstitution';

@Injectable({
    providedIn: 'root'
})
export class MedicalInstitutionsService {

    constructor(private http: HttpClient) {}

    getMedicalInstitutions() {
        return this.http.get<MedicalInstitution[]>(`${environment.api_endpoint}/api/medical-institutions`, {});
    }

    getPatientMedicalInstitutions() {
        return this.http.get<MedicalInstitution[]>(`${environment.api_endpoint}/api/patient/medical-institutions`, {});
    }
}
